import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";

import { permissionsMap } from "../../../constants/permissions";
import { Tabs } from "../../../constants/tabs";
import useFetchWithAbort from "../../../hooks/useFetchWithAbort";
import useTabs from "../../../hooks/useTabs";
import useTranslation from "../../../hooks/useTranslation";
import filtersAlertsStore from "../../../store/filters/alerts/alertsFiltersStore";
import filtersLimitsStore from "../../../store/filters/limitsFilterStore";
import filtersPartnersStore from "../../../store/filters/partnersFilterStore";
import filtersProcessingStore from "../../../store/filters/processingFilterStore";
import filtersServicesStore from "../../../store/filters/servicesFilterStore";
import { LocationState } from "../../../types/locationTypes";
import SecondHeader from "../../secondHeader/SecondHeader";
import { DetailsPageWrapper } from "../../ui/details/DetailsComponents";
import { useTabsActions } from "./hooks/useTabsActions";
import LimitsCharts from "./limits/LimitsCharts";
import MerchantCharts from "./merchant/MerchantCharts";
import TransactionsCharts from "./transactions/TransactionsCharts";

const PagesHome = () => {
    const { t } = useTranslation();
    const state: LocationState = useLocation()?.state;
    const defaultActiveTab = state?.tab;

    const tabsPropsList = [
        {
            title: t.home.text.transactions,
            name: Tabs.TRANSACTIONS,
            permission: permissionsMap.transactions.view,
            content: <TransactionsCharts />,
        },
        {
            title: t.home.text.limits,
            name: Tabs.LIMITS,
            permission: permissionsMap.limits.view,
            content: <LimitsCharts />,
        },
        {
            title: t.home.text.merchantInfo,
            name: Tabs.MERCHANT,
            permission: permissionsMap.transactions.view,
            content: <MerchantCharts />,
        },
    ];

    const {
        TabsLayout, activeTab, currentTabTitle,
    } = useTabs(tabsPropsList, defaultActiveTab);

    const { FilterModalComponent, actionHeaderContentList } = useTabsActions(currentTabTitle);

    useFetchWithAbort((signal: AbortSignal) => {
        filtersPartnersStore.fetch(signal);
        filtersServicesStore.fetch(signal);
        filtersProcessingStore.fetch(signal);
        filtersAlertsStore.fetch(signal);
        filtersLimitsStore.fetch(signal);
    }, []);

    return (
        <DetailsPageWrapper>
            <SecondHeader
                isMargin
                title={t.menu.links.home}
                tabs={TabsLayout}
                actionsContent={actionHeaderContentList}
            />

            {activeTab}

            {FilterModalComponent}
        </DetailsPageWrapper>
    );
};

export default observer(PagesHome);

const categoryOptionsHandbook = [
    { label: "handbooks.black", value: "Black" },
    { label: "handbooks.white", value: "White" },
    { label: "handbooks.alertException", value: "Alert Exception" },
    { label: "handbooks.other", value: "Other" }];

const statusOptionsHandbook = [
    { label: "status.active", value: true },
    { label: "status.inactive", value: false },
];

const proxyOptionsHandbook = [
    { label: "proxy.yes", value: "true" },
    { label: "proxy.no", value: "false" },
];

const groupOptionsHandbook = [
    { label: "group.global", value: true },
    { label: "group.local", value: false },
];

const typeOptionsHandbook = [
    { label: "table.header.ipAddresses", value: "IP" },
    { label: "handbooks.card", value: "Card" },
    { label: "table.header.email", value: "E-mail" },
    { label: "handbooks.customerId", value: "Customer ID" },
    { label: "table.header.phone", value: "Phone" },
    { label: "table.header.deviceId", value: "Device ID" },
];

const limitsTypeOptionsHandbook = [
    { label: "table.header.all", value: "All" },
    { label: "table.header.channel", value: true },
    { label: "table.header.notChannel", value: false },
];

const elementCardOptionsHandbook = [
    { label: "placeholder.cardHash", value: false },
    { label: "placeholder.cardPlain", value: true },
];

const transactionDecisionOptionsHandbook = [
    { label: "decision.decline", value: "decline" },
    { label: "decision.approve", value: "approve" },
    { label: "transactionStatus.onGoing", value: "on-going" },
];

const transactionStatusOptionsHandbook = [
    { label: "transactionStatus.decline", value: "decline" },
    { label: "transactionStatus.success", value: "success" },
    { label: "transactionStatus.onGoing", value: "on-going" },
];

const transactionTypeOptionsHandbook = [
    { label: "transactionType.payment", value: "payment" },
    { label: "transactionType.payout", value: "payout" },
    { label: "transactionType.refund", value: "refund" },
];

const transactionSubTypeOptionsHandbook = [
    { label: "transactionSubType.recurring", value: "recurring" },
    { label: "transactionSubType.initial", value: "initial" },
    { label: "transactionSubType.empty", value: "empty" },
];

const alertTypeOptionsHandbook = [
    { label: "alertType.af", value: "af" },
    { label: "alertType.tg", value: "tg" },
    { label: "alertType.email", value: "email" },
];

const alertTriggerOptionsHandbook = [
    { label: "alertTrigger.marker", value: "marker" },
];

const alertImportanceOptionsHandbook = [
    { label: "alertImportance.high", value: "high" },
    { label: "alertImportance.medium", value: "medium" },
    { label: "alertImportance.low", value: "low" },
];

const currencyOptionsHandbook = [
    { label: "USD", value: "USD" },
    { label: "RUB", value: "RUB" },
    { label: "EUR", value: "EUR" },
    { label: "JPY", value: "JPY" },
    { label: "KZT", value: "KZT" },
    { label: "AZN", value: "AZN" },
    { label: "MXN", value: "MXN" },
];

const rangeOptionsHandbook = [
    { label: "Today", duration: { unit: "day", value: null } },
    { label: "24h", duration: { unit: "hour", value: 24 } },
    { label: "7d", duration: { unit: "day", value: 7 } },
    { label: "30d", duration: { unit: "day", value: 30 } },
];

const cardMaskOptionsHandbook = [
    { label: "P2P", value: true },
    { label: "No P2P", value: false },
];

const attributesOptionsHandbook = [
    { label: "Service ID", value: "service_id" },
    { label: "Partner ID", value: "partner_id" },
    { label: "Customer ID", value: "customer_id" },
    { label: "E-mail", value: "email" },
    { label: "Phone", value: "phone" },
    { label: "IP", value: "ip" },
    { label: "Card Hash", value: "card_hash" },
    { label: "Created at", value: "created" },
];

const notificationsStatusOptionsHandbook = [
    { label: "status.checked", value: true },
    { label: "status.notChecked", value: false },
];

const queriesTypeOptionsHandbook = [
    { label: "queryType.manual", value: "manual" },
    { label: "queryType.periodic", value: "periodic" },
];

const queriesRangeOptionsHandbook = [
    { label: "queryRange.hour", value: "1 hour" },
    { label: "queryRange.day", value: "1 day" },
    { label: "queryRange.month", value: "1 month" },
    { label: "queryRange.threeMonths", value: "3 months" },
    { label: "queryRange.sixMonths", value: "6 months" },
    { label: "queryRange.year", value: "1 year" },
];

const queriesQueryPeriodHandbook = [
    { label: "queryPeriod.fifteenMinutes", value: "15m" },
    { label: "queryPeriod.hour", value: "1h" },
    { label: "queryPeriod.twentyFourHours", value: "24h" },
    { label: "queryPeriod.sevenDays", value: "7d" },
];

const getTranslatedHandbook = (translate: (key: string) => string, handbook: Option[]) => (
    handbook.map((option) => ({ ...option, label: translate(option.label) }))
);

export {
    categoryOptionsHandbook,
    statusOptionsHandbook,
    proxyOptionsHandbook,
    groupOptionsHandbook,
    typeOptionsHandbook,
    limitsTypeOptionsHandbook,
    elementCardOptionsHandbook,
    transactionDecisionOptionsHandbook,
    transactionStatusOptionsHandbook,
    transactionTypeOptionsHandbook,
    transactionSubTypeOptionsHandbook,
    alertTypeOptionsHandbook,
    alertTriggerOptionsHandbook,
    alertImportanceOptionsHandbook,
    attributesOptionsHandbook,
    notificationsStatusOptionsHandbook,
    currencyOptionsHandbook,
    cardMaskOptionsHandbook,
    queriesTypeOptionsHandbook,
    queriesRangeOptionsHandbook,
    queriesQueryPeriodHandbook,
    rangeOptionsHandbook,
    getTranslatedHandbook,
};

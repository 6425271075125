import {
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { observer } from "mobx-react-lite";

import rulesPackLimitsStore from "../../../../../../store/rulesPack/rulesPackLimitsStore";
import { RulesPacksLimitsTagsType } from "../../../../../../types/limitsTypes";
import Table from "../../../../../ui/table/Table";
import { ColumnType } from "../../../../../ui/table/types";
import DraggableCell from "../DraggableCell/DraggableCell";
import { tableContainerStyle } from "./RulesPacksLimitsMoveTableComponents";

type RulesPacksLimitsMoveTableTypes = {
    data: RulesPacksLimitsTagsType[];
    setData: (tags: RulesPacksLimitsTagsType[]) => void;
}

const columns: ColumnType<RulesPacksLimitsTagsType>[] = [
    {
        header: "table.header.order",
        field: "order",
        cellStyles: { width: "0px", fontWeight: 600 },
        padding: "13px 8px 13px 16px",
    },
    {
        field: "move",
        cellStyles: { width: "0px" },
        padding: "0px 8px",
        getField: ({ id }) => (<DraggableCell id={id} />),
    },
    {
        header: "table.header.limitId",
        field: "id",
        cellStyles: { width: "0px" },
        padding: "13px 16px",
    },
    {
        header: "table.header.limitName",
        field: "name",
    },
];

const RulesPacksDetailsLimitsMoveTable = ({ data, setData }: RulesPacksLimitsMoveTableTypes) => {
    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 200,
                tolerance: 6,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    return (
        <Table
            columns={columns}
            data={data}
            setData={setData}
            sensors={sensors}
            loading={rulesPackLimitsStore.loading}
            tableContainerStyles={tableContainerStyle}
            scrollable
        />
    );
};

export default observer(RulesPacksDetailsLimitsMoveTable);

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import useTranslation from "../../../../../hooks/useTranslation";
import transactionStore from "../../../../../store/transaction/transactionStore";
import Button from "../../../../ui/button/Button";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";
import { ModalButtonRow } from "../../details/components/sections/SectionsComponents";
import ParametersTable from "../ParametersTable/ParametersTable";

const ParametersModal = ({ isOpen, setOn }: ModalProps) => {
    const { t } = useTranslation();

    const [visibleColumns, setVisibleColumns] = useState(transactionStore.visibleColumns);

    const resetColumns = () => {
        setVisibleColumns(transactionStore.visibleColumns);
    };

    const closeModal = () => {
        setOn(false);
        resetColumns();
    };

    const confirmNewVisibleColumns = () => {
        transactionStore.setVisibleColumns(visibleColumns);
        closeModal();
    };

    useEffect(() => {
        if (visibleColumns && !visibleColumns.length) {
            const newColumns: typeof visibleColumns = [...visibleColumns, "id"];
            setVisibleColumns(newColumns);
            transactionStore.setVisibleColumns(newColumns);
        }
    }, [isOpen]);

    // TODO a kostyl - to be cut after finishing user-friendly app major update feature
    useEffect(() => {
        setVisibleColumns(transactionStore.visibleColumns);
    }, [transactionStore.visibleColumns?.length]);

    const isTableNotChanged = visibleColumns?.length === transactionStore.visibleColumns?.length
        && visibleColumns?.every((element) => transactionStore.visibleColumns?.includes(element));

    return (
        <Modal
            isOpen={isOpen}
            setOn={setOn}
            title={t.transactions.text.parametersModal}
            buttonText={t.buttons.apply}
            handleClose={resetColumns}
            submitButton={false}
            gap="0"
        >
            <ParametersTable visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} />
            <ModalButtonRow $marginTop="24px">
                <Button
                    variant="secondary"
                    type="button"
                    onClick={closeModal}
                >
                    {t.buttons.cancel}
                </Button>
                <Button
                    variant="primary"
                    type="submit"
                    disabled={isTableNotChanged}
                    onClick={confirmNewVisibleColumns}
                >
                    {t.buttons.save}
                </Button>
            </ModalButtonRow>
        </Modal>
    );
};

export default observer(ParametersModal);

import { RulesPacksLimitsTagsType } from "../../types/limitsTypes";
import request from "../request";

interface RequestType {
    limits: RulesPacksLimitsTagsType[]
}

interface ResponseType {
    message: string,
}

export const changeLimitsOrdering = (data: RequestType, rulesPackId: string) => request<ResponseType>({
    method: "POST",
    uri: `rules-packs/${rulesPackId}/reorder-limits/`,
    data,
});

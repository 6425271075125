import { useSortable } from "@dnd-kit/sortable";

import { ReactComponent as MoveIcon } from "../../../../../../resources/icons/table/move.svg";
import { DraggableButton } from "./DraggableCellComponents";

type DraggableCellTypes = {
    id: string | number;
}

const DraggableCell = ({ id }: DraggableCellTypes) => {
    const { attributes, listeners, isDragging } = useSortable({ id });

    return (
        <DraggableButton type="button" {...attributes} {...listeners} $isDragging={isDragging}>
            <MoveIcon />
        </DraggableButton>
    );
};

export default DraggableCell;

import {
    action,
    computed,
    flow,
    makeObservable,
    observable,
} from "mobx";

import getLimitsStatistic from "../../../api/home/getLimitsStatistic";
import { getCommonError } from "../../../helpers/apiHelper";
import { formatListDataDate, generateLimitsChartsConfigList } from "../../../helpers/statisticsHelper";
import { FetchStatusesType } from "../../../types/fetchStatuses";
import {
    LimitsChartConfigListTypes,
    LimitsPeriodicStatisticTypes,
    LimitsStatisticFiltersTypes,
    LimitsStatisticTypes,
} from "../../../types/limitsStatisticTypes";
import dataStore from "../../common/dataStore";

interface ResponseTypes {
    data: LimitsStatisticTypes;
}

class LimitsStore extends dataStore<LimitsPeriodicStatisticTypes, LimitsStatisticFiltersTypes, null> {
    @observable private _limitsStatistic: LimitsPeriodicStatisticTypes[] | null = null;

    @observable private _limitsChartConfigList: LimitsChartConfigListTypes[] = [];

    constructor() {
        super("LimitsStore");
        makeObservable(this);
    }

    @computed
    public get limitsStatistic() {
        return this._limitsStatistic;
    }

    @computed
    public get limitsChartConfigList() {
        return this._limitsChartConfigList;
    }

    @action
    public resetData() {
        this._limitsStatistic = null;
        this._limitsChartConfigList = [];
    }

    @action
    private setChartsData(data: ResponseTypes["data"]["response"]) {
        this._limitsStatistic = formatListDataDate(data.periodic, data.step);
    }

    @action
    private setLimitsChartConfigList() {
        if (this.limitsStatistic?.length) {
            this._limitsChartConfigList = generateLimitsChartsConfigList(
                this.limitsStatistic.at(0) as LimitsPeriodicStatisticTypes,
            );
        }
    }

    public fetchData = flow(function* fetchData(this: LimitsStore) {
        try {
            if (this._filters) {
                this.fetchState = FetchStatusesType.pending;

                const filtersData = this.parseFilters(this._filters);

                const response: ResponseTypes = yield getLimitsStatistic(filtersData);

                this.setChartsData(response.data.response);
                this.setLimitsChartConfigList();
                this.fetchState = FetchStatusesType.success;
                this._fetchErrors = null;
            }
        } catch (error) {
            this.fetchState = FetchStatusesType.failed;
            this._fetchErrors = getCommonError(error);
        }
    });
}

const limitsStore = new LimitsStore();

export default limitsStore;

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
    action, computed, flow, makeObservable, observable,
} from "mobx";

import { getCount } from "../../api/transactions/getCount";
import { getTransactionsFile, GetFileResponseType } from "../../api/transactions/getTransactionsFile";
import { getTransactionsFileId, GetFileIdResponse } from "../../api/transactions/getTransactionsFileId";
import { getSelectedTransaction } from "../../api/transactions/getSelectedTransaction";
import { getTransactions } from "../../api/transactions/getTransactions";
import postAllAttributesToBlacklist from "../../api/transactions/postAllAttributesToBlacklist";
import { objectToValuesList, parseDateFromServer } from "../../helpers";
import { downloadFile } from "../../helpers/downloadFile";
import { generateTransactionsFile } from "../../api/transactions/generateTransactionsFile";
import { FetchStatusesType } from "../../types/fetchStatuses";
import {
    PostAllAttributesToBlacklistRequestType, TransactionFiltersTypes, TransactionTypes,
} from "../../types/transactionTypes";
import authStore from "../auth/authStore";
import dataStore from "../common/dataStore";
import { DATE_BACK_FORMAT } from "../../constants/dates";

dayjs.extend(utc);

const TRANSACTION_DEFAULT_FILTERS = {
    created_from: dayjs().startOf("day").utc().format(DATE_BACK_FORMAT),
    created_to: dayjs().endOf("day").utc().format(DATE_BACK_FORMAT),
};

const orderingFieldsWithRequiredFilters = ["email", "customer_id"];

const defaultVisibleColumns: (keyof TransactionTypes)[] = [
    "id",
    "risk_score",
    "type",
    "processing_name",
    "partner_name",
    "partner",
    "service_name",
    "service",
    "subtype",
    "order_id",
    "is_proxy",
    "create_time",
    "last_update",
    "finalize_time",
    "rules_packs",
    "decision",
    "status",
    "processing_status",
    "code",
    "markers",
    "amount",
    "currency",
    "customer_id",
    "device_id",
    "email",
    "ip",
    "phone",
    "country",
    "country_by_ip",
    "country_by_card",
    "channel_id",
    "card_mask_hask",
    "card_hash",
    "cardholder",
];

class TransactionStore extends dataStore<TransactionTypes, TransactionFiltersTypes, null> {
    protected fetchDataMethod = getTransactions;

    protected fetchSelectedItemMethod = getSelectedTransaction;

    @observable private _count: string | null = null;

    @observable private lastId: number | null = null;

    @observable private fetchCountState = FetchStatusesType.unset;

    @observable private fetchFileState = FetchStatusesType.unset;

    @observable private _visibleColumns: { [key: string]: typeof defaultVisibleColumns } = {};

    constructor() {
        super("TransactionStore", ["_count", "_visibleColumns"]);
        makeObservable(this);
        this._filters = TRANSACTION_DEFAULT_FILTERS;
        this.resetFilters = this._resetFilters.bind(this);
    }

    @computed
    public get defaultFilters() {
        return TRANSACTION_DEFAULT_FILTERS;
    }

    @computed
    public get count() {
        return this._count;
    }

    @computed
    public get visibleColumns() {
        return this._visibleColumns[authStore.userId];
    }

    @computed
    public get isCountLoading() {
        return this.fetchCountState === FetchStatusesType.pending;
    }

    @computed
    public get isFileLoading() {
        return this.fetchFileState === FetchStatusesType.pending;
    }

    @computed
    public get isFetchFileSuccess() {
        return this.fetchFileState === FetchStatusesType.success;
    }

    @computed
    public get isFetchFileFailed() {
        return this.fetchFileState === FetchStatusesType.failed;
    }

    _resetFilters() {
        this._filters = TRANSACTION_DEFAULT_FILTERS;
        this.resetCount();
    }

    @action
    public resetCount = () => {
            this._count = null;
        };

    @action
    public setDefaultVisibleColumns = (userId: string) => {
            if (!this._visibleColumns[userId]?.length) {
                this._visibleColumns[userId] = defaultVisibleColumns;
            }
        };

    @action
    public setVisibleColumns = (visibleColumns: (keyof TransactionTypes)[]) => {
            this._visibleColumns[authStore.userId] = visibleColumns;
        };

    fetchSelectedItem = flow(function* fetchSelectedItem(
        this: TransactionStore,
        id: number | string,
    ) {
        const response = yield this.fetchSelectedItemWithoutSet(id, true);
        if (response?.data.response) {
            [this._selectedItem] = parseDateFromServer<TransactionTypes>(
                [response.data.response],
                "create_time",
                "last_update",
                "finalize_time",
            );
        }
    });

    fetchData = flow(function* fetchData(this: TransactionStore, signal?: AbortSignal) {
        this.resetSortWithRequiredFiltration(!!objectToValuesList(this.filters as any || {}).length);
        const response = yield this.fetchWithoutSet(signal);
        if (response?.data.response.results) {
            this._data = parseDateFromServer<TransactionTypes>(
                response.data.response.results,
                "create_time",
                "last_update",
                "finalize_time",
            );
            this._pagination.count = response.data.response.count;
            this._pagination.is_lazy_count = response.data.response.is_lazy_count;
            if (this.pagination.page === 1 && (!this.ordering || this.ordering === "-create_time")) {
                const last_transaction_id = this.data.reduce(
                    (maxId, transaction) => (maxId > transaction.id ? maxId : transaction.id),
                    0,
                );
                this.lastId = last_transaction_id;
            }
        }
    });

    downloadData = flow(function* downloadData(this: TransactionStore) {
        try {
            this.fetchFileState = FetchStatusesType.pending;

            const fileIdResponse: GetFileIdResponse = yield getTransactionsFileId({
                filters: this._filters,
                fields: this._visibleColumns[authStore.userId],
            });

            yield generateTransactionsFile(fileIdResponse.data.response.task_id);

            const getFileResponse: GetFileResponseType = yield getTransactionsFile(
                fileIdResponse.data.response.task_id,
            );

            downloadFile(getFileResponse.data, `transactions_${new Date().getTime()}.xlsx`);

            this.fetchFileState = FetchStatusesType.success;
        } catch (error) {
            this.fetchFileState = FetchStatusesType.failed;
        }
    });

    addAllAttributesToBlacklist = flow(function* addAllAttributesToBlacklist(
        this: TransactionStore,
        data: PostAllAttributesToBlacklistRequestType,
    ) {
        try {
            this.postState = FetchStatusesType.pending;
            yield postAllAttributesToBlacklist(data);
            this._postErrors = null;
            this.postState = FetchStatusesType.success;
        } catch (error) {
            this.postState = FetchStatusesType.failed;
            this._postErrors = (error as any)?.response?.data?.response?.message;
        }
    });

    updateCount = flow(function* updateCount(
        this: TransactionStore,
    ) {
        try {
            this.fetchCountState = FetchStatusesType.pending;
            const filters = this.parseFilters(this._filters);
            const response = yield getCount({ last_transaction_id: this.lastId || 0, ...filters });
            this._count = response.data.response.count;
            this.fetchCountState = FetchStatusesType.success;
        } catch (error) {
            this.fetchCountState = FetchStatusesType.failed;
        }
    });

    resetSortWithRequiredFiltration(isFiltersApplied: boolean) {
        if (!isFiltersApplied && this._ordering && orderingFieldsWithRequiredFilters.includes(this._ordering)) {
            this._ordering = undefined;
        }
    }

    @action
    public updateFiltersWithClear = (_filters: typeof this._filters) => {
            if (_filters) {
                const filters: { [key: string]: any } = _filters && { ...this._filters, ..._filters };
                Object.keys(filters).forEach((filter) => {
                    if (typeof filters[filter] === "boolean" && !filters[filter]) {
                        delete filters[filter];
                    }
                });
                this._filters = filters;
                this._pagination.page = 1;
            }
        };
}

const transactionStore = new TransactionStore();

export default transactionStore;

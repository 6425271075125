import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent } from "react";
import * as Yup from "yup";

import { validateDateRange } from "../../../../../helpers/validationHelper";
import useTranslation from "../../../../../hooks/useTranslation";
import limitsStore from "../../../../../store/home/limits/limitsStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import { LimitsStatisticFiltersTypes } from "../../../../../types/limitsStatisticTypes";
import {
    FormContainer,
    HeaderContainer,
    HeaderTitle,
} from "../../../../filters/FiltersModalComponents";
import Button from "../../../../ui/button/Button";
import { TextButton } from "../../../../ui/button/ButtonStyles";
import { WarningText } from "../../components/Chart/ChartsFiltersComponents";
import FieldsList from "./FieldsList";

interface LimitsChartsFiltersProps {
    closeModal: () => void;
}

const LimitsChartsFilters = ({ closeModal }: LimitsChartsFiltersProps) => {
    const { t, translate } = useTranslation();

    const formik = useFormik<LimitsStatisticFiltersTypes>({
        initialValues: { ...limitsStore.filters },
        enableReinitialize: true,

        validationSchema: Yup.object({
            created_to: validateDateRange(translate, "created_from"),
        }),

        initialTouched: {
            created_to: !!limitsStore.filters?.created_to,
        },

        onSubmit: async (fields) => {
            limitsStore.updateFilters(fields);
            await limitsStore.fetchData();

            if (limitsStore.isFetchRequestSuccess) {
                closeModal();
            } else {
                snackbarStore.add({ variant: "error", text: limitsStore.fetchErrors });
            }
        },
    });

    const isSubmitButtonDisabled = (!formik.values.created_from || !formik.values.created_to);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        limitsStore.resetFilters();
        limitsStore.resetData();
        formik.resetForm();
    };

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.home.filters.limits}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>

            <FormContainer onSubmit={handleSubmit} $scrollable>
                <FieldsList formik={formik} />

                <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitButtonDisabled}
                    isLoading={limitsStore.loading}
                >
                    {t.filters.apply}
                </Button>
                {isSubmitButtonDisabled && (
                    <WarningText>{t.home.filtersModal.warning.limits}</WarningText>
                )}
            </FormContainer>
        </>
    );
};

export default observer(LimitsChartsFilters);

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import useTranslation from "../../../../../hooks/useTranslation";
import rulesPackStore from "../../../../../store/rulesPack/rulesPackStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import { RulesPacksTagTypes } from "../../../../../types/rulesPacksTypes";
import BlockModal from "../../../../common/modal/warning/blockModal/BlockModal";
import Button from "../../../../ui/button/Button";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";
import { Icon } from "../../../../ui/new/NewComponents";
import { ModalButtonRow } from "../../../transactions/details/components/sections/SectionsComponents";
import RulesPacksMoveTable from "../RulesPacksMoveTable/RulesPacksMoveTable";

const RulesPacksMoveModal = ({ setOn, isOpen }: ModalProps) => {
    const { t } = useTranslation();

    const [rulesPacksTags, setRulesPacksTags] = useState<RulesPacksTagTypes[]>([]);

    const [isWarningModalVisible, setWarningModalVisibility] = useState(false);
    const toggleWarningModal = () => setWarningModalVisibility((prev) => !prev);

    const sortedRulesPacksTags = [...rulesPackStore.orderingRulesPacks].sort((a, b) => a.order - b.order);
    const isOrderingChanged = rulesPacksTags.some(({ id }, index) => (id !== sortedRulesPacksTags[index].id));

    const closeMoveModal = () => {
        if (isOrderingChanged) {
            toggleWarningModal();
        } else {
            setOn(false);
        }
    };

    const changeRulesPacksOrdering = async () => {
        await rulesPackStore.changeOrdering(rulesPacksTags);

        if (!rulesPackStore.postErrors?.common) {
            snackbarStore.add({ variant: "success", text: t.snackbar.editSuccess });
        } else {
            snackbarStore.add({ variant: "error", text: t.snackbar.editError });
        }

        setOn(false);
    };

    const changeRulesPacksOrder = (currentRulesPacksTags: RulesPacksTagTypes[]) => {
        const newRulesPacksTags = currentRulesPacksTags.map((tag, index) => ({ ...tag, order: index + 1 }));

        setRulesPacksTags(newRulesPacksTags);
    };

    const closeWarningModal = () => {
        setWarningModalVisibility(false);
        setOn(false);
    };

    useEffect(() => {
        if (isOpen) rulesPackStore.fetchTagsData();
    }, [isOpen]);

    useEffect(() => {
        setRulesPacksTags(sortedRulesPacksTags);
    }, [rulesPackStore.orderingRulesPacks]);

    return (
        <>
            <Modal
                gap="0"
                width="984px"
                title={t.rulesPacks.text.moveLimit}
                isOpen={isOpen}
                submitButton={false}
                setOn={setOn}
                hasChildModal
                scrollable
                handleClose={closeMoveModal}
            >
                <RulesPacksMoveTable data={rulesPacksTags} setData={changeRulesPacksOrder} />

                <ModalButtonRow $marginTop="auto">
                    <Button
                        variant="secondary"
                        type="button"
                        onClick={() => setOn(false)}
                    >
                        {t.buttons.cancel}
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        isLoading={rulesPackStore.loading}
                        disabled={!isOrderingChanged || rulesPackStore.loading}
                        onClick={changeRulesPacksOrdering}
                    >
                        {t.buttons.save}
                    </Button>
                </ModalButtonRow>
            </Modal>

            <BlockModal
                isOpen={isWarningModalVisible}
                title={<Icon />}
                submitButton={false}
                blockOverflow={false}
                setOn={toggleWarningModal}
                toggleModal={toggleWarningModal}
                handleNavigate={closeWarningModal}
            />
        </>
    );
};

export default observer(RulesPacksMoveModal);

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import useTranslation from "../../../../../../hooks/useTranslation";
import rulesPackLimitsStore from "../../../../../../store/rulesPack/rulesPackLimitsStore";
import snackbarStore from "../../../../../../store/snackbar/snackbarStore";
import { RulesPacksLimitsTagsType } from "../../../../../../types/limitsTypes";
import BlockModal from "../../../../../common/modal/warning/blockModal/BlockModal";
import Button from "../../../../../ui/button/Button";
import Modal, { ModalProps } from "../../../../../ui/modal/Modal";
import { Icon } from "../../../../../ui/new/NewComponents";
import { ModalButtonRow } from "../../../../transactions/details/components/sections/SectionsComponents";
import RulesPacksDetailsLimitsMoveTable from "../RulesPacksLimitsMoveTable/RulesPacksLimitsMoveTable";

type RulesPacksLimitsMoveModalProps = ModalProps & {
    ruleId?: string
}

const RulesPacksLimitsMoveModal = ({ setOn, isOpen, ruleId }: RulesPacksLimitsMoveModalProps) => {
    const { t } = useTranslation();

    const [limitsTags, setLimitsTags] = useState<RulesPacksLimitsTagsType[]>([]);

    const [isWarningModalVisible, setWarningModalVisibility] = useState(false);
    const toggleWarningModal = () => setWarningModalVisibility((prev) => !prev);

    const sortedLimitsTags = [...rulesPackLimitsStore.orderingLimits].sort((a, b) => a.order - b.order);
    const isOrderingChanged = limitsTags.some(({ id }, index) => (id !== sortedLimitsTags[index].id));

    const changeLimitsOrdering = async () => {
        if (ruleId) {
            await rulesPackLimitsStore.changeOrdering(limitsTags, ruleId);

            if (!rulesPackLimitsStore.postErrors?.common) {
                snackbarStore.add({ variant: "success", text: t.snackbar.editSuccess });
            } else {
                snackbarStore.add({ variant: "error", text: t.snackbar.editError });
            }

            setOn(false);
        }
    };

    const closeMoveModal = () => {
        if (isOrderingChanged) {
            toggleWarningModal();
        } else {
            setOn(false);
        }
    };

    const closeWarningModal = () => {
        setWarningModalVisibility(false);
        setOn(false);
    };

    const changeLimitsOrder = (currentLimitsTags: RulesPacksLimitsTagsType[]) => {
        const newLimitsTags = currentLimitsTags.map((tag, index) => ({ ...tag, order: index + 1 }));

        setLimitsTags(newLimitsTags);
    };

    useEffect(() => {
        if (ruleId && isOpen) rulesPackLimitsStore.fetchTagsData(Number(ruleId));
    }, [ruleId, isOpen]);

    useEffect(() => {
        setLimitsTags(sortedLimitsTags);
    }, [rulesPackLimitsStore.orderingLimits]);

    return (
        <>
            <Modal
                gap="0"
                width="984px"
                title={t.rulesPacks.details.moveLimit}
                isOpen={isOpen}
                submitButton={false}
                setOn={setOn}
                handleClose={closeMoveModal}
                hasChildModal
                scrollable
            >
                <RulesPacksDetailsLimitsMoveTable data={limitsTags} setData={changeLimitsOrder} />
                <ModalButtonRow $marginTop="auto">
                    <Button
                        variant="secondary"
                        type="button"
                        onClick={() => setOn(false)}
                    >
                        {t.buttons.cancel}
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        isLoading={rulesPackLimitsStore.loading}
                        disabled={!isOrderingChanged || rulesPackLimitsStore.loading}
                        onClick={changeLimitsOrdering}
                    >
                        {t.buttons.save}
                    </Button>
                </ModalButtonRow>
            </Modal>
            <BlockModal
                isOpen={isWarningModalVisible}
                title={<Icon />}
                submitButton={false}
                setOn={toggleWarningModal}
                toggleModal={toggleWarningModal}
                handleNavigate={closeWarningModal}
                blockOverflow={false}
            />
        </>
    );
};

export default observer(RulesPacksLimitsMoveModal);

import {
    action,
    computed,
    flow,
    makeObservable,
    observable,
} from "mobx";

import activateLimits from "../../api/limits/activateLimits";
import deactivateLimits from "../../api/limits/deactivateLimits";
import { getLimits } from "../../api/limits/getLimits";
import { changeLimitsOrdering } from "../../api/rulesPacks/changeLimitsOrdering";
import getRulesPacksLimits from "../../api/rulesPacks/getRulesPacksLimits";
import { parseDateFromServer } from "../../helpers";
import { FetchStatusesType } from "../../types/fetchStatuses";
import { LimitsTypes, NewLimitType, RulesPacksLimitsTagsType } from "../../types/limitsTypes";
import dataStore from "../common/dataStore";

class RulesPackLimitsStore extends dataStore<LimitsTypes, any, NewLimitType> {
    protected deactivateSelectedItemsMethod = deactivateLimits;

    protected activateSelectedItemsMethod = activateLimits;

    protected fetchDataByIdMethod = getLimits;

    @observable protected _orderingLimits: RulesPacksLimitsTagsType[] = [];

    constructor() {
        super();
        makeObservable(this);
    }

    @computed
    public get orderingLimits() {
        return this._orderingLimits;
    }

    fetchData = flow(function* fetchData(this: RulesPackLimitsStore, id: number | string) {
        const response = yield this.fetchDataByIdWithoutSet({ rules_pack: id });
        if (response?.data.response.results) {
            this._data = parseDateFromServer<LimitsTypes>(
                response.data.response.results,
                "created",
                "last_update",
            );
            this._pagination.count = response.data.response.count;
        }
    });

    fetchTagsData = flow(function* fetchData(this: RulesPackLimitsStore, id: number) {
        try {
            this.fetchState = FetchStatusesType.pending;
            const response = yield getRulesPacksLimits(id);
            if (response?.data.response) {
                this._orderingLimits = response.data.response;
            }
            this.fetchState = FetchStatusesType.success;
        } catch (error) {
            this.fetchState = FetchStatusesType.failed;
        }
    });

    changeOrdering = flow(function* changeOrdering(
        this: RulesPackLimitsStore,
        limits: RulesPacksLimitsTagsType[],
        rulesPackId: string,
    ) {
        try {
            this.postState = FetchStatusesType.pending;
            yield changeLimitsOrdering({ limits }, rulesPackId);
            yield this.fetchData(rulesPackId);
            this._postErrors = null;
            this.postState = FetchStatusesType.success;
        } catch (error) {
            this.postState = FetchStatusesType.failed;
            this._postErrors = (error as any)?.response?.data?.response?.message;
        }
    });

    @action
    public setOrderingLimits = (data: typeof this.orderingLimits) => {
            this._orderingLimits = data;
        };
}

const rulesPackLimitsStore = new RulesPackLimitsStore();

export default rulesPackLimitsStore;

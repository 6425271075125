import styled from "styled-components";

export const DraggableButton = styled.button<{ $isDragging: boolean }>`
    border: none;
    outline: none;
    background: none;
    padding: 0;
    height: 16px;
    width: 16px;

    cursor: ${({ $isDragging }) => ($isDragging ? "grabbing" : "grab")};
`;

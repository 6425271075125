import { FormFieldType } from "../../../../../constants/filterTypes";
import {
    cardMaskOptionsHandbook,
    currencyOptionsHandbook,
    getTranslatedHandbook,
    transactionSubTypeOptionsHandbook,
    transactionTypeOptionsHandbook,
} from "../../../../../constants/handbooks";
import { FieldMode } from "../../../../../constants/selectFields";
import useTranslation from "../../../../../hooks/useTranslation";
import filtersAlertsStore from "../../../../../store/filters/alerts/alertsFiltersStore";
import filtersPartnersStore from "../../../../../store/filters/partnersFilterStore";
import filtersProcessingStore from "../../../../../store/filters/processingFilterStore";
import filtersServicesStore from "../../../../../store/filters/servicesFilterStore";
import { FormikTypes } from "../../../../../types/formikTypes";
import { ListsFiltersTypes } from "../../../../../types/listsTypes";
import { ScrollableFormContainer, SubTitle } from "../../../../filters/FiltersModalComponents";
import DatePicker from "../../../../ui/datepicker/DatePicker";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import RangeButtons from "../../components/RangeButtons/RangeButtons";

interface FieldListProps {
    formik: FormikTypes<ListsFiltersTypes>;
}

const isAlertsFieldsShown = false;

const FieldsList = ({ formik }: FieldListProps) => {
    const { t, translate } = useTranslation();

    const fieldsOptionsMap = {
        currency: getTranslatedHandbook(translate, currencyOptionsHandbook),
        cardMask: getTranslatedHandbook(translate, cardMaskOptionsHandbook),
        type: getTranslatedHandbook(translate, transactionTypeOptionsHandbook),
        subtype: getTranslatedHandbook(translate, transactionSubTypeOptionsHandbook),
    };

    const formFieldsList = [
        {
            key: "charts-filter-partner",
            type: FormFieldType.floatSelect,
            name: "partners",
            mode: FieldMode.Multiple,
            options: filtersPartnersStore.options,
            placeholder: t.filters.fields.partner,
        },
        {
            key: "charts-filter-service",
            type: FormFieldType.floatSelect,
            name: "services",
            mode: FieldMode.Multiple,
            options: filtersServicesStore.options,
            placeholder: t.filters.fields.service,
        },
        {
            key: "charts-filter-processing",
            type: FormFieldType.floatSelect,
            name: "processings",
            mode: FieldMode.Multiple,
            options: filtersProcessingStore.options,
            placeholder: t.filters.fields.processing,
        },
        {
            key: "charts-filter-type",
            type: FormFieldType.floatSelect,
            name: "transaction_types",
            mode: FieldMode.Multiple,
            options: fieldsOptionsMap.type,
            placeholder: t.table.header.transactionType,
        },
        {
            key: "charts-filter-subtype",
            type: FormFieldType.floatSelect,
            name: "transaction_subtype",
            options: fieldsOptionsMap.subtype,
            placeholder: t.table.header.transactionSubtype,
        },
        {
            key: "charts-filter-currency",
            type: FormFieldType.floatSelect,
            name: "currency",
            mode: FieldMode.Multiple,
            options: fieldsOptionsMap.currency,
            placeholder: t.filters.fields.currency,
        },
        {
            key: "charts-filter-card-mask",
            type: FormFieldType.floatSelect,
            name: "is_p2p",
            options: fieldsOptionsMap.cardMask,
            placeholder: t.filters.fields.isP2P,
        },
        ...(isAlertsFieldsShown ? [
            { type: FormFieldType.subTitle, content: t.filters.fields.alerts, key: "charts-filter-alerts" },
            {
                key: "charts-filter-alert",
                type: FormFieldType.floatSelect,
                name: "alerts",
                mode: FieldMode.Multiple,
                options: filtersAlertsStore.options,
                placeholder: t.filters.fields.alert,
            },
        ] : []
        ),
        { type: FormFieldType.subTitle, content: t.filters.fields.creationDate, key: "charts-filter-date" },
        {
            key: "charts-filter-created-from",
            type: FormFieldType.datePicker,
            name: "created_from",
            maxDate: formik.values.created_to,
            placeholder: t.filters.fields.from,
        },
        {
            key: "charts-filter-created-to",
            type: FormFieldType.datePicker,
            name: "created_to",
            minDate: formik.values.created_from,
            placeholder: t.filters.fields.to,
            isAddMilliseconds: true,
        },
    ];

    return (
        <ScrollableFormContainer>
            {formFieldsList.map((field) => {
                const {
                    type,
                    key,
                    content,
                    name,
                    placeholder,
                    options,
                    mode,
                    maxDate,
                    minDate,
                    isAddMilliseconds,
                } = field;

                switch (type) {
                    case FormFieldType.floatSelect:
                        return (
                            <FloatSelect
                                key={key}
                                formik={formik}
                                name={name}
                                mode={mode}
                                options={options}
                                placeholder={placeholder}
                            />
                        );
                    case FormFieldType.subTitle:
                        return <SubTitle key={key}>{content}</SubTitle>;
                    case FormFieldType.datePicker:
                        return (
                            <DatePicker
                                key={key}
                                formik={formik}
                                name={name}
                                maxDate={maxDate}
                                minDate={minDate}
                                placeholder={placeholder}
                                isAddMilliseconds={isAddMilliseconds}
                            />
                        );
                    default:
                        return null;
                }
            })}

            <RangeButtons setFieldValue={formik.setFieldValue} />
        </ScrollableFormContainer>
    );
};

export default FieldsList;

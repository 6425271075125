import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction } from "react";
import Table from "../../../../ui/table/Table";
import { TransactionParametersTable, TransactionTypes } from "../../../../../types/transactionTypes";
import useTranslation from "../../../../../hooks/useTranslation";
import { propertiesCellStyle, tableContainerStyle } from "./ParametersTableComponents";
import { ColumnType } from "../../../../ui/table/types";

interface ParametersTableProps {
    visibleColumns: (keyof TransactionTypes)[]
    setVisibleColumns: Dispatch<SetStateAction<(keyof TransactionTypes)[]>>,
}

const ParametersTable = ({ visibleColumns, setVisibleColumns }: ParametersTableProps) => {
    const { t } = useTranslation();

    const defaultVisibleColumns: TransactionParametersTable[] = [
        { id: "id", field: t.table.header.transactionId },
        { id: "risk_score", field: t.table.header.risk },
        { id: "type", field: t.table.header.type },
        { id: "processing_name", field: t.table.header.processing },
        { id: "partner_name", field: t.table.header.partnerName },
        { id: "partner", field: t.table.header.partnerId },
        { id: "service_name", field: t.table.header.serviceName },
        { id: "service", field: t.table.header.serviceId },
        { id: "subtype", field: t.table.header.subtype },
        { id: "order_id", field: t.table.header.orderId },
        { id: "create_time", field: t.table.header.createdAt },
        { id: "last_update", field: t.table.header.lastUpdate },
        { id: "finalize_time", field: t.table.header.finalizeTime },
        { id: "rules_packs", field: t.table.header.rulesPack },
        { id: "decision", field: t.table.header.decision },
        { id: "status", field: t.table.header.isActive },
        { id: "processing_status", field: t.table.header.processingStatus },
        { id: "code", field: t.table.header.code },
        { id: "markers", field: t.table.header.marker },
        { id: "amount", field: t.table.header.amount },
        { id: "currency", field: t.table.header.currency },
        { id: "customer_id", field: t.table.header.customerId },
        { id: "device_id", field: t.table.header.deviceId },
        { id: "email", field: t.table.header.email },
        { id: "ip", field: t.table.header.ipAddresses },
        { id: "is_proxy", field: t.table.header.isProxy },
        { id: "phone", field: t.table.header.phone },
        { id: "country", field: t.table.header.country },
        { id: "country_by_ip", field: t.table.header.countryByIP },
        { id: "country_by_card", field: t.table.header.countryByCard },
        { id: "channel_id", field: t.table.header.channelId },
        { id: "card_mask_hask", field: t.table.header.cardMask },
        { id: "card_hash", field: t.table.header.cardHash },
        { id: "cardholder", field: t.table.header.cardholder },
    ];

    const columns: ColumnType<TransactionParametersTable>[] = [
        { field: "checkbox", cellStyles: { width: "0px", height: "40px" } },
        {
            header: "table.header.parameter",
            field: "field",
            cellStyles: propertiesCellStyle,
        },
    ];

    const setCurrentVisibleColumns = (itemId: string | number | null) => {
        if (itemId && typeof itemId === "string") {
            let currentVisibleColumns = visibleColumns;
            if (currentVisibleColumns && currentVisibleColumns.includes(itemId as keyof TransactionTypes)) {
                currentVisibleColumns = currentVisibleColumns.filter((id) => id !== itemId);
            } else {
                currentVisibleColumns = [...currentVisibleColumns || [], itemId as keyof TransactionTypes];
            }
            setVisibleColumns(currentVisibleColumns as (keyof TransactionTypes)[]);
        }
    };

    return (
        <Table
            tableContainerStyles={tableContainerStyle}
            columns={columns}
            data={defaultVisibleColumns}
            checkedItems={visibleColumns}
            onCheckboxChange={setCurrentVisibleColumns}
        />
    );
};

export default observer(ParametersTable);

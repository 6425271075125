import { RulesPacksTagTypes } from "../../types/rulesPacksTypes";
import request from "../request";

interface GetRulesPacksTagsResponse {
    message: string,
    response: RulesPacksTagTypes[],
}

const getRulesPacksTags = (signal?: AbortSignal) => request<GetRulesPacksTagsResponse>({
    method: "GET",
    uri: "rules-packs/tags/",
    signal,
});

export default getRulesPacksTags;

import { Editor as DefaultEditor } from "@monaco-editor/react";
import styled from "styled-components";

import { colors } from "../../../styles/variables";

const Container = styled.div``;

const Editor = styled(DefaultEditor)<{ $disabled?: boolean }>`
    min-height: 421px;
    border-radius: 12px;
    border: 1px solid ${colors.inputDefaultBorder};

    .monaco-editor, .overflow-guard {
        border-radius: 12px;

        .line-numbers, .line-numbers.active-line-number {
            color: ${colors.activeText}
        }
    }
`;

export {
    Container,
    Editor,
};

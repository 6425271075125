import { observer } from "mobx-react-lite";

import { permissionsMap } from "../../../constants/permissions";
import { generateHeaderName } from "../../../helpers/secondHeaderHelper";
import useFetchWithAbort from "../../../hooks/useFetchWithAbort";
import useTranslation from "../../../hooks/useTranslation";
import authStore from "../../../store/auth/authStore";
import filtersCreatedByStore from "../../../store/filters/createdByFilterStore";
import filtersProcessingStore from "../../../store/filters/processingFilterStore";
import filtersRulesPacksStore from "../../../store/filters/rulesPacksFiltersStore";
import filtersTagsStore from "../../../store/filters/tagsFiltersStore";
import SecondHeader from "../../secondHeader/SecondHeader";
import { useTabsActions } from "./hooks/useTabsActions";
import ProtectedRulesPacksTable from "./ProtectedRulesPacksTable";

const PagesRulesPacks = () => {
    const { t } = useTranslation();
    const { userPermissions } = authStore;

    const currentTabTitle = t.rulesPacks.title;
    const headerTitle = generateHeaderName(currentTabTitle);

    const {
        FilterModalComponent,
        MoveModalComponent,
        ActionModalComponent,
        actionHeaderContentList,
    } = useTabsActions(userPermissions);

    useFetchWithAbort((signal: AbortSignal) => {
        filtersRulesPacksStore.fetch(signal);
        filtersTagsStore.fetch(signal);
        filtersProcessingStore.fetch(signal);
        filtersCreatedByStore.fetch(signal);
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />
            <ProtectedRulesPacksTable permission={permissionsMap.rules_packs.view} />
            {FilterModalComponent}
            {MoveModalComponent}
            {ActionModalComponent}
        </>
    );
};

export default observer(PagesRulesPacks);

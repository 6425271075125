/* eslint-disable no-param-reassign */
import dayjs from "dayjs";

import { LimitsChartConfigListTypes, LimitsPeriodicStatisticTypes } from "../types/limitsStatisticTypes";
import { MerchantChartEntryTypes } from "../types/merchantTypes";
import { PeriodStep } from "../types/transactionsStatisticTypes";
import { generateRandomColor, generateUniqueId } from "./index";

const formatsMap: Record<PeriodStep, "HH:mm" | "MMM D"> = {
    five_min: "HH:mm",
    hour: "HH:mm",
    six_hours: "MMM D",
    day: "MMM D",
    two_days: "MMM D",
    week: "MMM D",
};

const formatListDataDate = <T extends Record<string, any>>(data: T[], step: PeriodStep): T[] => {
    if (step === "six_hours") {
        return data.map((statistic, index) => {
            const formattedDate = dayjs(statistic.date).format(formatsMap.day);
            const previousFormattedDate = index > 0 && dayjs(data[index - 1].date).format(formatsMap.day);
            const formattedTime = dayjs(statistic.date).format(formatsMap.hour);

            const date = formattedDate === previousFormattedDate ? formattedTime : formattedDate;

            return { ...statistic, date };
        });
    }

    return data.map((statistic) => ({ ...statistic, date: dayjs(statistic.date).format(formatsMap[step]) }));
};

const formatMapDataDate = (
    data: Record<string, MerchantChartEntryTypes[]>,
    step: PeriodStep,
): Record<string, MerchantChartEntryTypes[]> => (
    Object.keys(data).reduce((formattedData, currency) => {
        formattedData[currency] = formatListDataDate(data[currency], step);
        return formattedData;
    }, {} as Record<string, MerchantChartEntryTypes[]>)
);

const generateLimitsChartsConfigList = (limitsData: LimitsPeriodicStatisticTypes): LimitsChartConfigListTypes[] => (
    Object.keys(limitsData)
        .filter((key) => key !== "date")
        .map((limitName) => ({
            key: generateUniqueId(),
            name: limitName,
            dataKey: limitName,
            activeDot: false,
            stroke: generateRandomColor(),
        })));

export {
    formatListDataDate,
    formatMapDataDate,
    generateLimitsChartsConfigList,
};

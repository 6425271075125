import { EditorProps, OnMount } from "@monaco-editor/react";
import * as monacoEditor from "monaco-editor";
import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import useTranslation from "../../../hooks/useTranslation";
import snackbarStore from "../../../store/snackbar/snackbarStore";
import { FormikTypes } from "../../../types/formikTypes";
import ErrorLabel from "../errors/ValidationErrorBox/ErrorLabel";
import { Container, Editor } from "./CodeEditorComponents";
import { getEditorRange, getEditorSuggestions } from "./editorHelpers";

export type CodeEditorProps = EditorProps & {
    name?: string;
    error?: boolean;
    onlyNumber?: boolean;
    maxIntegerDigits?: number;
    maxDecimalDigits?: number;
    disabled?: boolean;
    serverErrors?: Record<string, string>;
    showDisabledSnackbar?: boolean;
    formik?: FormikTypes;
    helperText?: React.ReactNode;
    resetServerError?: (field: string) => void;
}

const MAX_SNACKBAR_COUNT = 4;

const CodeEditor = ({
    formik,
    serverErrors,
    disabled,
    name = "",
    value,
    showDisabledSnackbar = true,
    resetServerError,
    onChange,
    ...props
}: CodeEditorProps) => {
    const monacoRef = useRef<monacoEditor.editor.IStandaloneCodeEditor | null>(null);
    const { t } = useTranslation();
    const [code, setCode] = useState(value);

    const error = useMemo(() => (
        serverErrors?.[name] || (formik?.touched?.[name] && formik.errors?.[name])
    ), [serverErrors, formik, name]);

    const isError = !!error;

    const editorOptions: EditorProps["options"] = {
        scrollbar: { vertical: "hidden" },
        minimap: { enabled: false },
        domReadOnly: disabled,
        readOnly: disabled,
    };

    const handleEditorChange: EditorProps["onChange"] = (newValue, event) => {
        formik?.setFieldTouched(name, false);
        resetServerError?.(name);
        onChange?.(newValue, event);
        formik?.setFieldValue(name, newValue);
    };

    const handleDisabledEditor = () => {
        if (disabled && showDisabledSnackbar && snackbarStore.all.length <= MAX_SNACKBAR_COUNT) {
            snackbarStore.add({ variant: "warning", text: t.limits.text.editCodePopup });
        }
    };

    const generateCompletionItems: OnMount = (editor, monaco) => {
        monacoRef.current = editor;

        const completionItemsProvider = monaco.languages.registerCompletionItemProvider("go", {
            provideCompletionItems: (model, position) => {
                const word = model.getWordAtPosition(position);
                const range = getEditorRange(monaco, position, word);
                const suggestions = getEditorSuggestions(monaco, range);

                return { suggestions };
            },
        });

        editor.onDidDispose(() => completionItemsProvider.dispose());
    };

    useEffect(() => {
        setCode(value || formik?.values[name]);
    }, [formik?.values[name], value, name]);

    return (
        <Container role="presentation" onClick={handleDisabledEditor}>
            <Editor
                {...props}
                height="100%"
                defaultLanguage="go"
                value={code}
                onChange={handleEditorChange}
                options={editorOptions}
                onMount={generateCompletionItems}
            />

            {isError && ErrorLabel(error || "")}
        </Container>
    );
};

export default CodeEditor;

import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent } from "react";
import * as Yup from "yup";

import { getTranslatedHandbook, typeOptionsHandbook } from "../../../constants/handbooks";
import { FieldMode } from "../../../constants/selectFields";
import { validateDateRange } from "../../../helpers/validationHelper";
import useTranslation from "../../../hooks/useTranslation";
import elementStore from "../../../store/element/elementStore";
import filtersCreatedByStore from "../../../store/filters/createdByFilterStore";
import filtersListsStore from "../../../store/filters/lists/listsFiltersStore";
import { FiltersElementsType } from "../../../types/elementsTypes";
import {
    FormContainer, HeaderContainer, HeaderTitle, SubTitle,
} from "../../filters/FiltersModalComponents";
import Button from "../../ui/button/Button";
import { TextButton } from "../../ui/button/ButtonStyles";
import DatePicker from "../../ui/datepicker/DatePicker";
import FloatInput from "../../ui/textInput/FloatInput";
import FloatSelect from "../../ui/textInput/FloatSelect";
import useFetchWithAbort from "../../../hooks/useFetchWithAbort";

interface ElementsFiltersProps {
    closeModal: () => void;
}

const ElementsFilters = ({ closeModal }: ElementsFiltersProps) => {
    const { t, translate } = useTranslation();

    const typeOptions = getTranslatedHandbook(translate, typeOptionsHandbook);

    const formik = useFormik<Omit<FiltersElementsType, "null">>({
        initialValues: { ...elementStore.filters },
        enableReinitialize: true,
        validationSchema: Yup.object({
            created_to: validateDateRange(translate, "created_from"),
        }),
        initialTouched: {
            created_to: !!elementStore.filters?.created_to,
        },
        onSubmit: (fields) => {
            elementStore.updateFilters(fields);
            closeModal();
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        elementStore.resetFilters();
        formik.resetForm();
    };

    useFetchWithAbort((signal: AbortSignal) => {
        filtersListsStore.fetch(signal);
        filtersCreatedByStore.fetch(signal);
    }, []);

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.elements.filters}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>

            <FormContainer onSubmit={handleSubmit}>
                <FloatInput
                    formik={formik}
                    name="name"
                    placeholder={t.filters.fields.element}
                />
                <SubTitle>{t.filters.fields.creationDate}</SubTitle>
                <DatePicker
                    formik={formik}
                    name="created_from"
                    maxDate={formik.values.created_to}
                    placeholder={t.filters.fields.from}
                />
                <DatePicker
                    formik={formik}
                    name="created_to"
                    minDate={formik.values.created_from}
                    placeholder={t.filters.fields.to}
                    isAddMilliseconds
                />
                <FloatSelect
                    formik={formik}
                    name="created_by"
                    options={filtersCreatedByStore.options}
                    placeholder={t.filters.fields.createdBy}
                />
                <SubTitle>{t.filters.fields.lists}</SubTitle>
                <FloatSelect
                    formik={formik}
                    name="list"
                    mode={FieldMode.Multiple}
                    options={filtersListsStore.options}
                    placeholder={t.filters.fields.list}
                />
                <FloatSelect
                    formik={formik}
                    name="list_type"
                    mode={FieldMode.Multiple}
                    options={typeOptions}
                    placeholder={t.filters.fields.listType}
                />
                <Button variant="primary" isLoading={elementStore.loading} type="submit">{t.filters.apply}</Button>
            </FormContainer>
        </>
    );
};

export default observer(ElementsFilters);

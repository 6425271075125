import dayjs, { ManipulateType } from "dayjs";

import { rangeOptionsHandbook } from "../../../../../constants/handbooks";
import { Row } from "../../../../detailsHeader/DetailsHeaderComponents";
import SmallButton from "../../../../ui/button/SmallButton";

interface FieldListProps {
    setFieldValue: (field: string, value: string) => void;
}

const RangeButtons = ({ setFieldValue }: FieldListProps) => {
    const setDateRange = (label: string) => {
        const currentDate = dayjs();

        const date = rangeOptionsHandbook.find((config) => config.label === label);

        if (date) {
            const from = date.duration.value
                ? currentDate.subtract(date.duration.value, date.duration.unit as ManipulateType).toISOString()
                : dayjs().startOf(date.duration.unit as ManipulateType).toISOString();

            const to = currentDate.toISOString();

            setFieldValue("created_from", from);
            setFieldValue("created_to", to);
        }
    };

    return (
        <Row>
            {rangeOptionsHandbook.map(({ label }) => (
                <SmallButton
                    key={label}
                    width="100%"
                    variant="secondary"
                    type="button"
                    onClick={() => setDateRange(label)}
                >
                    {label}
                </SmallButton>
            ))}
        </Row>
    );
};

export default RangeButtons;

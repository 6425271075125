import { RulesPacksTagTypes } from "../../types/rulesPacksTypes";
import request from "../request";

type RequestType = {
    tags: RulesPacksTagTypes[];
}

type ResponseType = {
    message: string;
}

export const changeRulesPacksOrdering = (data: RequestType) => request<ResponseType>({
    method: "POST",
    uri: "rules-packs/reorder-tags/",
    data,
});

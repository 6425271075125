import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent } from "react";
import * as Yup from "yup";

import { getTranslatedHandbook, limitsTypeOptionsHandbook } from "../../../constants/handbooks";
import { validateDateRange } from "../../../helpers/validationHelper";
import useTranslation from "../../../hooks/useTranslation";
import filtersCreatedByStore from "../../../store/filters/createdByFilterStore";
import filtersLimitsStore from "../../../store/filters/limitsFilterStore";
import rulesPacksFiltersStore from "../../../store/filters/rulesPacksFiltersStore";
import limitStore from "../../../store/limit/limitStore";
import { LimitsFiltersTypes } from "../../../types/limitsTypes";
import {
    FormContainer,
    HeaderContainer,
    HeaderTitle,
    SubTitle,
} from "../../filters/FiltersModalComponents";
import Button from "../../ui/button/Button";
import { TextButton } from "../../ui/button/ButtonStyles";
import DatePicker from "../../ui/datepicker/DatePicker";
import FloatSelect from "../../ui/textInput/FloatSelect";

interface LimitsFiltersProps {
    closeModal: () => void;
}

const LimitsFilters = ({ closeModal }: LimitsFiltersProps) => {
    const { t, translate } = useTranslation();

    const typeOptions = getTranslatedHandbook(translate, limitsTypeOptionsHandbook);

    const formik = useFormik<LimitsFiltersTypes>({
        initialValues: { ...limitStore.filters },
        enableReinitialize: true,

        validationSchema: Yup.object({
            created_to: validateDateRange(translate, "created_from"),
            updated_to: validateDateRange(translate, "updated_from"),
        }),

        initialTouched: {
            created_to: !!limitStore.filters?.created_to,
            updated_to: !!limitStore.filters?.updated_to,
        },

        onSubmit: (fields) => {
            limitStore.updateFilters(fields);
            closeModal();
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        limitStore.resetFilters();
        formik.resetForm();
    };

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.limits.filters}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>

            <FormContainer onSubmit={handleSubmit}>
                <FloatSelect
                    formik={formik}
                    name="id"
                    options={filtersLimitsStore.options}
                    placeholder={t.filters.fields.limit}
                />
                <FloatSelect
                    formik={formik}
                    name="channel_check"
                    options={typeOptions}
                    placeholder={t.table.header.type}
                />
                <SubTitle>{t.filters.fields.creationDate}</SubTitle>
                <DatePicker
                    formik={formik}
                    maxDate={formik.values.created_to}
                    name="created_from"
                    placeholder={t.filters.fields.from}
                />
                <DatePicker
                    formik={formik}
                    minDate={formik.values.created_from}
                    name="created_to"
                    placeholder={t.filters.fields.to}
                    isAddMilliseconds
                />

                <FloatSelect
                    formik={formik}
                    name="created_by"
                    options={filtersCreatedByStore.options}
                    placeholder={t.filters.fields.createdBy}
                />
                <SubTitle>{t.filters.fields.lastUpdate}</SubTitle>
                <DatePicker
                    formik={formik}
                    name="updated_from"
                    maxDate={formik.values.updated_to}
                    placeholder={t.filters.fields.from}
                />
                <DatePicker
                    formik={formik}
                    name="updated_to"
                    minDate={formik.values.updated_from}
                    placeholder={t.filters.fields.to}
                    isAddMilliseconds
                />
                <SubTitle>{t.filters.fields.rulesPacks}</SubTitle>
                <FloatSelect
                    formik={formik}
                    name="rules_pack"
                    options={rulesPacksFiltersStore.options}
                    placeholder={t.filters.fields.rulesPack}
                />
                <Button variant="primary" isLoading={limitStore.loading} type="submit">{t.filters.apply}</Button>
            </FormContainer>
        </>
    );
};

export default observer(LimitsFilters);

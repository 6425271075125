import { observer } from "mobx-react-lite";

import { permissionsMap } from "../../../constants/permissions";
import { generateHeaderName } from "../../../helpers/secondHeaderHelper";
import useFetchWithAbort from "../../../hooks/useFetchWithAbort";
import useTranslation from "../../../hooks/useTranslation";
import authStore from "../../../store/auth/authStore";
import filtersAlertsStore from "../../../store/filters/alerts/alertsFiltersStore";
import createdByFilterStore from "../../../store/filters/createdByFilterStore";
import filtersListsStore from "../../../store/filters/lists/listsFiltersStore";
import filtersProcessingStore from "../../../store/filters/processingFilterStore";
import filtersRulesPacksStore from "../../../store/filters/rulesPacksFiltersStore";
import SecondHeader from "../../secondHeader/SecondHeader";
import ProtectedListsTable from "./components/ProtectedListsTable";
import { useTabsActions } from "./hooks/useTabsActions";

const PagesLists = () => {
    const { t } = useTranslation();
    const { userPermissions } = authStore;
    const headerTitle = generateHeaderName(t.lists.title);

    const isListsTableCheckboxVisible = userPermissions.includes(permissionsMap.lists.changeStatus);

    const {
        CloneListModalComponent, FilterModalComponent, ActionModalComponent, actionHeaderContentList,
    } = useTabsActions();

    useFetchWithAbort((signal: AbortSignal) => {
        filtersListsStore.fetch(signal);
        filtersAlertsStore.fetch(signal);
        filtersProcessingStore.fetch(signal);
        filtersRulesPacksStore.fetch(signal);
        createdByFilterStore.fetch(signal);
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />
            <ProtectedListsTable
                permission={permissionsMap.lists.view}
                isCheckboxVisible={isListsTableCheckboxVisible}
            />
            {CloneListModalComponent}
            {FilterModalComponent}
            {ActionModalComponent}
        </>
    );
};

export default observer(PagesLists);

import useTranslation from "../../../../hooks/useTranslation";
import limitsStore from "../../../../store/home/limits/limitsStore";
import TabHeader from "../../../tabHeader/TabHeader";
import EmptyView from "../../../ui/emptyView/EmptyView";
import Chart from "../components/Chart/Chart";

const LimitsCharts = () => {
    const { t } = useTranslation();
    const { limitsStatistic, limitsChartConfigList } = limitsStore;

    if (!limitsStatistic) {
        return (
            <EmptyView
                title={t.home.emptyView.title}
                text={t.home.emptyView.limits.subtext}
            />
        );
    }

    if (!limitsStatistic.length) {
        return (
            <EmptyView
                title={t.home.emptyView.noDataTitle}
                text={t.home.emptyView.limits.text}
            />
        );
    }

    return (
        <>
            <TabHeader title={t.home.chartsTitle.limits} />
            <Chart data={limitsStatistic} lines={limitsChartConfigList} />
        </>
    );
};

export default LimitsCharts;

/* eslint-disable no-template-curly-in-string */
import * as monacoEditor from "monaco-editor";

const ATTRIBUTES_LIST = [
    "PartnerId",
    "GateId",
    "ChannelId",
    "ServiceId",
    "ProcessingId",
    "OrderId",
    "OrderAmount",
    "OrderCurrency",
    "OrderType",
    "OrderSubtype",
    "CustomerId",
    "CustomerEmail",
    "CustomerEmailDomain",
    "CustomerIp",
    "CustomerPhone",
    "CustomerCountry",
    "CustomerCountryByIp",
    "CustomerCountryByCard",
    "CustomerAccount",
    "CustomerCity",
    "CustomerAddress",
    "DeviceId",
    "CardHash",
    "CardMask",
    "CardHolder",
    "CardCountry",
];

const getEditorRange = (
    monaco: typeof monacoEditor,
    position: monacoEditor.Position,
    word: monacoEditor.editor.IWordAtPosition | null,
): monacoEditor.Range => {
    const startColumn = word?.startColumn ?? position.column;
    const endColumn = word?.endColumn ?? position.column;

    return new monaco.Range(
        position.lineNumber,
        startColumn,
        position.lineNumber,
        endColumn,
    );
};

const getEditorSuggestions = (
    monaco: typeof monacoEditor,
    range: monacoEditor.Range,
): monacoEditor.languages.CompletionItem[] => (
    ATTRIBUTES_LIST.map((attribute) => ({
        label: `tr.${attribute}`,
        kind: monaco.languages.CompletionItemKind.Value,
        insertText: `tr.${attribute}`,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range,
    }))
);

export {
    getEditorRange,
    getEditorSuggestions,
};

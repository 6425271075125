/* eslint-disable camelcase */
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import {
    categoryOptionsHandbook,
    getTranslatedHandbook,
    groupOptionsHandbook,
    typeOptionsHandbook,
} from "../../../../constants/handbooks";
import Routes from "../../../../constants/routes";
import { MAX_DESCRIPTION_FILED_LENGTH } from "../../../../constants/validationRules";
import { removeEmptyFields } from "../../../../helpers/removeEmptyFields";
import { usePreventRedirect } from "../../../../hooks/usePreventRedirect";
import useTranslation from "../../../../hooks/useTranslation";
import alertsFiltersStore from "../../../../store/filters/alerts/alertsFiltersStore";
import processingFiltersStore from "../../../../store/filters/processingFilterStore";
import rulesPacksFiltersStore from "../../../../store/filters/rulesPacksFiltersStore";
import limitStore from "../../../../store/limit/limitStore";
import listStore from "../../../../store/list/listStore";
import snackbarStore from "../../../../store/snackbar/snackbarStore";
import { ListsCategoryTypes, NewListType } from "../../../../types/listsTypes";
import SecondHeader from "../../../secondHeader/SecondHeader";
import Button from "../../../ui/button/Button";
import SmallButton from "../../../ui/button/SmallButton";
import {
    Container,
    DescriptionFieldWrapper,
    Row,
    SubmitButtonWrapper,
    ThreeColumnForm,
    Title,
} from "../../../ui/new/NewComponents";
import Switch from "../../../ui/switch/Switch";
import StatusBadge from "../../../ui/textField/StatusBadge";
import FloatInput from "../../../ui/textInput/FloatInput";
import FloatSelect from "../../../ui/textInput/FloatSelect";
import FloatTextArea from "../../../ui/textInput/FloatTextArea";
import {
    fieldsList,
    getFieldStates,
    initialValues,
    validationSchema,
} from "../helpers/newListFormikHelpers";
import useFetchWithAbort from "../../../../hooks/useFetchWithAbort";

const NewList = () => {
    const { t, translate } = useTranslation();
    const navigation = useNavigate();

    const fieldsOptionsMap = {
        type: getTranslatedHandbook(translate, typeOptionsHandbook),
        category: getTranslatedHandbook(translate, categoryOptionsHandbook),
        group: getTranslatedHandbook(translate, groupOptionsHandbook),
    };

    const [
        blackCategory,
        whiteCategory,
        alertExceptionCategory,
        otherCategory,
    ] = categoryOptionsHandbook.map((category) => category?.value as ListsCategoryTypes);

    const handleNavigateToPrevPage = () => navigation(Routes.lists.href);

    const formik = useFormik<NewListType>({
        initialValues,
        validationSchema: validationSchema(t, translate),

        onSubmit: async (fields) => {
            const filteredData = removeEmptyFields(fields, "is_active", "is_global");

            await listStore.postData(filteredData, fieldsList);

            if (listStore.isPostRequestSuccess) {
                handleNavigateToPrevPage();
                listStore.resetPostItemState();
                snackbarStore.add({ variant: "success", text: t.lists.snackbar.addSuccess });
            }
        },
    });

    const categoryMap: Record<string, typeof formik.values> = {
        [whiteCategory]: { category: whiteCategory },
        [blackCategory]: { category: blackCategory },
        [otherCategory]: { category: otherCategory, is_global: true, type: null },
        [alertExceptionCategory]: { category: alertExceptionCategory, is_global: true },
    };

    const fieldStates = getFieldStates(formik.values, listStore.loading);

    const updateFormikValues = (overrides: Partial<typeof formik.values>) => {
        formik.setValues({
            ...formik.values,
            rules_pack: null,
            alert: null,
            ...overrides,
        }, false);
    };

    const handleCategoryChange = (value: unknown) => {
        if (value) {
            const overrides = categoryMap[value as ListsCategoryTypes];
            updateFormikValues(overrides);
        }
    };

    const toggleStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const handleListGroupChange = () => {
        if (!formik.values.is_global) {
            formik.setFieldValue("rules_pack", null, false);
        } else {
            formik.setFieldValue("processings", null, false);
        }
    };

    const actionHeaderContentList = [
        {
            key: "cancel_button",
            content: (
                <SmallButton variant="secondary" type="button" onClick={handleNavigateToPrevPage}>
                    {t.buttons.cancel}
                </SmallButton>
            ),
        },
    ];

    const { RedirectBlockModalComponent } = usePreventRedirect(formik.dirty, listStore.isPostRequestSuccess);

    useFetchWithAbort((signal: AbortSignal) => {
        rulesPacksFiltersStore.fetch(signal);
        processingFiltersStore.fetch(signal);
        alertsFiltersStore.fetch(signal);
    }, [], listStore.resetPostErrors);

    return (
        <>
            <SecondHeader title={t.lists.new.headerTitle} actionsContent={actionHeaderContentList} />

            <Container>
                <Row $spacing>
                    <Title>{t.lists.text.title}</Title>
                    <Row>
                        <Title>{t.status.title}</Title>
                        <StatusBadge
                            variant={formik.values.is_active ? "success" : "inactive"}
                            multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                        />
                        <Switch
                            checked={formik.values.is_active}
                            disabled={listStore.loading}
                            onChange={toggleStatus}
                        />
                    </Row>
                </Row>

                <ThreeColumnForm onSubmit={handleSubmit}>
                    <FloatInput
                        formik={formik}
                        disabled={listStore.loading}
                        name="name"
                        placeholder={t.lists.placeholders.name}
                        isRequired
                        serverErrors={listStore.postErrors}
                        resetServerError={listStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        disabled={listStore.loading}
                        placeholder={t.lists.placeholders.category}
                        isRequired
                        name="category"
                        options={fieldsOptionsMap.category}
                        onChange={handleCategoryChange}
                        serverErrors={listStore.postErrors}
                        resetServerError={listStore.resetPostError}
                    />
                    {fieldStates.isAlertExceptionSelected && (
                        <FloatSelect
                            formik={formik}
                            disabled={listStore.loading}
                            placeholder={t.lists.placeholders.alert}
                            isRequired
                            name="alert"
                            options={alertsFiltersStore.options}
                            serverErrors={listStore.postErrors}
                            resetServerError={listStore.resetPostError}
                        />
                    )}
                    <FloatSelect
                        formik={formik}
                        disabled={fieldStates.isTypeDisabled}
                        placeholder={t.lists.placeholders.type}
                        isRequired={!fieldStates.isOtherCategorySelected}
                        name="type"
                        options={fieldsOptionsMap.type}
                        serverErrors={listStore.postErrors}
                        resetServerError={listStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        disabled={fieldStates.isGroupDisabled}
                        placeholder={t.lists.placeholders.group}
                        isRequired={!fieldStates.isGroupRequired}
                        name="is_global"
                        onChange={handleListGroupChange}
                        options={fieldsOptionsMap.group}
                        serverErrors={listStore.postErrors}
                        resetServerError={listStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        disabled={!!fieldStates.isRulesPackDisabled}
                        isRequired={!fieldStates.isRulesPackSelectDisabled}
                        placeholder={t.lists.placeholders.rulesPack}
                        name="rules_pack"
                        options={rulesPacksFiltersStore.options}
                        serverErrors={limitStore.postErrors}
                        resetServerError={limitStore.resetPostError}
                    />

                    {fieldStates.isGlobalGroupSelected && (
                        <FloatSelect
                            formik={formik}
                            disabled={listStore.loading}
                            isRequired={!!fieldStates.isGlobalGroupSelected}
                            mode="multiple"
                            placeholder={t.lists.placeholders.processing}
                            name="processings"
                            options={processingFiltersStore.options}
                            serverErrors={limitStore.postErrors}
                            resetServerError={limitStore.resetPostError}
                        />
                    )}

                    {fieldStates.isGlobalGroupSelectedWithoutAlerts ? (
                        <DescriptionFieldWrapper>
                            <FloatTextArea
                                formik={formik}
                                disabled={listStore.loading}
                                name="description"
                                placeholder={t.placeholder.description}
                                serverErrors={listStore.postErrors}
                                resetServerError={listStore.resetPostError}
                                autoSize
                                maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                            />
                        </DescriptionFieldWrapper>
                    ) : (
                        <FloatTextArea
                            formik={formik}
                            disabled={listStore.loading}
                            name="description"
                            placeholder={t.placeholder.description}
                            serverErrors={listStore.postErrors}
                            resetServerError={listStore.resetPostError}
                            autoSize
                            maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                        />
                    )}

                    <SubmitButtonWrapper>
                        <Button
                            type="submit"
                            variant="primary"
                            isLoading={listStore.loading}
                        >
                            {t.buttons.add}
                        </Button>
                    </SubmitButtonWrapper>
                </ThreeColumnForm>
            </Container>
            {RedirectBlockModalComponent}
        </>
    );
};

export default observer(NewList);

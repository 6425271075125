import {
    action,
    computed,
    flow,
    makeObservable,
    observable,
} from "mobx";

import activateRulesPacks from "../../api/rulesPacks/activateRulesPacks";
import { changeRulesPacksOrdering } from "../../api/rulesPacks/changeRulesPacksOrdering";
import deactivateRulesPacks from "../../api/rulesPacks/deactivateRulesPacks";
import getRulesPacks from "../../api/rulesPacks/getRulesPacks";
import getRulesPacksTags from "../../api/rulesPacks/getRulesPacksTags";
import getSelectedRulesPack from "../../api/rulesPacks/getSelectedRules";
import patchRulesPack from "../../api/rulesPacks/patchRulesPack";
import postRulesPack from "../../api/rulesPacks/postRulesPack";
import { parseDateFromServer } from "../../helpers";
import { FetchStatusesType } from "../../types/fetchStatuses";
import {
    NewRuleTypes,
    RulesPacksFiltersTypes,
    RulesPacksTagTypes,
    RulesPacksTypes,
} from "../../types/rulesPacksTypes";
import dataStore from "../common/dataStore";

class RulesPackStore extends dataStore<RulesPacksTypes, RulesPacksFiltersTypes, NewRuleTypes> {
    protected fetchDataMethod = getRulesPacks;

    protected fetchSelectedItemMethod = getSelectedRulesPack;

    protected deactivateSelectedItemsMethod = deactivateRulesPacks;

    protected activateSelectedItemsMethod = activateRulesPacks;

    protected postDataMethod = postRulesPack;

    protected patchDataMethod = patchRulesPack;

    @observable protected _orderingRulesPacks: RulesPacksTagTypes[] = [];

    constructor() {
        super("RulesPackStore");
        makeObservable(this);
    }

    @computed
    public get orderingRulesPacks() {
        return this._orderingRulesPacks;
    }

    fetchData = flow(function* fetchData(this: RulesPackStore, fetch?: AbortSignal) {
        const response = yield this.fetchWithoutSet(fetch);
        if (response?.data.response.results) {
            this._data = parseDateFromServer<RulesPacksTypes>(
                response.data.response.results,
                "created",
                "last_update",
            );
            this._pagination.count = response.data.response.count;
        }
    });

    fetchTagsData = flow(function* fetchData(this: RulesPackStore) {
        try {
            this.fetchState = FetchStatusesType.pending;
            const response = yield getRulesPacksTags();
            if (response?.data.response) {
                this._orderingRulesPacks = response.data.response;
            }
            this.fetchState = FetchStatusesType.success;
        } catch (error) {
            this.fetchState = FetchStatusesType.failed;
        }
    });

    changeOrdering = flow(function* changeOrdering(
        this: RulesPackStore,
        tags: RulesPacksTagTypes[],
    ) {
        try {
            this.postState = FetchStatusesType.pending;
            yield changeRulesPacksOrdering({ tags });
            yield this.fetchData();
            this._postErrors = null;
            this.postState = FetchStatusesType.success;
        } catch (error) {
            this.postState = FetchStatusesType.failed;
            this._postErrors = (error as any)?.response?.data?.response?.message;
        }
    });

    @action
    public setOrderingRulesPacks = (data: typeof this.orderingRulesPacks) => {
            this._orderingRulesPacks = data;
        };
}

const rulesPackStore = new RulesPackStore();

export default rulesPackStore;
